import React from "react";
import {
  ILesson,
  ILessonPage,
  ILessonPageSection,
  LessonManagementData,
  PaginatedLesson,
} from "../types/lesson";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  CREATE_LESSON_MUTATION,
  CREATE_PAGE_MUTATION,
  CREATE_SECTION_MUTATION,
  DELETE_SECTION_MUTATION,
  DELETE_LESSON_MUTATION,
  FETCH_FEATURED_LESSONS_QUERY,
  FETCH_LESSONS_BY_AUTHOR_ID,
  FETCH_LESSON_QUERY,
  UPDATE_PAGE_MUTATION,
  UPDATE_SECTION_MUTATION,
  UPDATE_LESSON_MUTATION,
  FETCH_PAGINATED_LESSSONS_BY_AUTHOR_ID,
  FETCH_LESSONS_BY_TITLE_QUERY,
} from "../graphql";
import {
  APIToModel,
  ModelToAPIInput,
} from "../views/LessonCreation/data-transformer";
import { Lesson, SectionContentInput } from "../__generated__/graphql";
import { Clients } from "..";

interface UseLessonApiClient {
  loading: boolean;
  createLesson: (data: LessonManagementData) => Promise<ILesson>;
  updateLesson: (
    lessonId: string,
    data: LessonManagementData
  ) => Promise<ILesson>;
  fetchAuthorIdByLesson: (lessonId: string) => Promise<string>;
  fetchLesson: (lessonId: string) => Promise<ILesson>;
  fetchFeaturedLessons: () => Promise<ILesson[]>;
  fetchLessonsByAuthorId: (
    authorId: string,
    organizationId: string
  ) => Promise<ILesson[]>;
  fetchPaginatedLessonsByAuthorId: (
    authorId: string,
    organizationId: string,
    page: number,
    size: number
  ) => Promise<PaginatedLesson>;
  fetchLessonsByTitle: (title: string) => Promise<ILesson[]>;
  saveLessonPage: (page: ILessonPage) => Promise<{ pageId: string }>;
  saveLessonSection: (
    pageId: string,
    section: ILessonPageSection
  ) => Promise<{ sectionId: string }>;
  deleteLessonSection: (sectionId: string) => Promise<{ sectionId: string }>;
  deleteLesson: (lessonId: string) => Promise<{ lessonId: string }>;
}

function useLessonApiClient(): UseLessonApiClient {
  const [createLessonMutation] = useMutation(CREATE_LESSON_MUTATION, {
    client: Clients.LESSON,
  });
  const [updateLessonMutation] = useMutation(UPDATE_LESSON_MUTATION, {
    client: Clients.LESSON,
  });
  const [createPageMutation] = useMutation(CREATE_PAGE_MUTATION, {
    client: Clients.LESSON,
  });
  const [updatePageMutation] = useMutation(UPDATE_PAGE_MUTATION, {
    client: Clients.LESSON,
  });
  const [createSectionMutation] = useMutation(CREATE_SECTION_MUTATION, {
    client: Clients.LESSON,
  });
  const [updateSectionMutation] = useMutation(UPDATE_SECTION_MUTATION, {
    client: Clients.LESSON,
  });
  const [deleteLessonMutation] = useMutation(DELETE_LESSON_MUTATION, {
    client: Clients.LESSON,
  });
  const [deleteSectionMutation] = useMutation(DELETE_SECTION_MUTATION, {
    client: Clients.LESSON,
  });

  const [fetchLessonQuery] = useLazyQuery(FETCH_LESSON_QUERY, {
    client: Clients.LESSON,
  });

  const [fetchFeaturedLessonsQuery] = useLazyQuery(
    FETCH_FEATURED_LESSONS_QUERY,
    {
      client: Clients.LESSON,
    }
  );
  const [fetchLessonsByAuthorIdQuery] = useLazyQuery(
    FETCH_LESSONS_BY_AUTHOR_ID,
    {
      client: Clients.LESSON,
    }
  );
  const [fetchPaginatedLessonsByAuthorIdQuery] = useLazyQuery(
    FETCH_PAGINATED_LESSSONS_BY_AUTHOR_ID,
    {
      client: Clients.LESSON,
    }
  );
  const [fetchLessonsByTitleQuery] = useLazyQuery(
    FETCH_LESSONS_BY_TITLE_QUERY,
    {
      client: Clients.LESSON,
    }
  );

  const [loading, setLoading] = React.useState(false);

  const createLesson = React.useCallback(
    async ({ title, imageUrl }: LessonManagementData): Promise<ILesson> => {
      setLoading(true);
      return await createLessonMutation({
        variables: { data: { title, imageUrl } },
      })
        .then((result) => {
          if (!result.data?.createLesson?.data) {
            throw new Error(
              result.data?.createLesson.error?.message ??
                "Error occured while creating lesson."
            );
          }
          return APIToModel.transformLesson(
            result.data.createLesson.data as Lesson
          );
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const updateLesson = React.useCallback(
    async (lessonId: string, { title, imageUrl }: LessonManagementData) => {
      setLoading(true);
      return await updateLessonMutation({
        variables: {
          updateLessonId: lessonId,
          data: { title, imageUrl },
        },
      })
        .then((result) => {
          if (!result.data?.updateLesson?.data) {
            throw new Error(
              result.data?.updateLesson.error?.message ??
                "Error occured while updating lesson."
            );
          }
          return APIToModel.transformLesson(
            result.data.updateLesson.data as Lesson
          );
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchLesson = React.useCallback(
    async (lessonId: string): Promise<ILesson> => {
      setLoading(true);
      return await fetchLessonQuery({
        variables: { lessonId },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          if (!result.data?.lesson?.data) {
            throw new Error(
              result.data?.lesson.error?.message ??
                "Error occurred while fetching lesson."
            );
          }
          return APIToModel.transformLesson(result.data.lesson.data as Lesson);
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchAuthorIdByLesson = React.useCallback(
    async (lessonId: string): Promise<string> => {
      setLoading(true);
      return await fetchLessonQuery({
        variables: { lessonId },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          if (!result.data?.lesson?.data) {
            throw new Error(
              result.data?.lesson.error?.message ??
                "Error occured while fetching author ID."
            );
          }
          return result.data.lesson.data.authorId;
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchLessonsByAuthorId = React.useCallback(
    async (authorId: string, organizationId: string): Promise<ILesson[]> => {
      setLoading(true);
      return await fetchLessonsByAuthorIdQuery({
        variables: { authorId, organizationId },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          if (!result.data?.lessonsByAuthorId?.data) {
            throw new Error(
              result.data?.lessonsByAuthorId.error?.message ??
                "Unable to fetch lessons by author ID."
            );
          }
          return result.data.lessonsByAuthorId.data.map((lesson) =>
            APIToModel.transformLesson(lesson as Lesson)
          );
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchPaginatedLessonsByAuthorId = React.useCallback(
    async (
      authorId: string,
      organizationId: string,
      page: number,
      size: number
    ): Promise<PaginatedLesson> => {
      setLoading(true);
      return await fetchPaginatedLessonsByAuthorIdQuery({
        variables: { authorId, organizationId, page, size },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          if (!result.data?.paginatedLessonsByAuthorId?.data) {
            throw new Error(
              result.data?.paginatedLessonsByAuthorId?.error?.message ??
                "Unable to fetch paginated lessons by author ID."
            );
          }
          const data = result.data.paginatedLessonsByAuthorId.data;
          return {
            lessons:
              data.content?.map((lesson) =>
                APIToModel.transformLesson(lesson as Lesson)
              ) ?? [],
            totalElements: data.totalElements ?? 0,
            totalPages: data.totalPages ?? 0,
            hasNext: data.hasNext ?? false,
          };
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchLessonsByTitle = React.useCallback(
    async (title: string): Promise<ILesson[]> => {
      setLoading(true);
      return await fetchLessonsByTitleQuery({
        variables: { title },
        fetchPolicy: "network-only",
      })
        .then((result) => {
          if (!result.data?.lessonsByTitle?.data) {
            throw new Error(
              result.data?.lessonsByTitle.error?.message ??
                "Unable to fetch lessons by title."
            );
          }
          return result.data.lessonsByTitle.data.map((lesson) =>
            APIToModel.transformLesson(lesson as Lesson)
          );
        })
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchFeaturedLessons = React.useCallback(async (): Promise<
    ILesson[]
  > => {
    setLoading(true);
    return await fetchFeaturedLessonsQuery({ fetchPolicy: "network-only" })
      .then((result) => {
        if (!result.data?.featuredLessons?.data) {
          throw new Error(
            result.data?.featuredLessons.error?.message ??
              "Error occured while fetching featured lessons."
          );
        }
        return result.data.featuredLessons.data.map((lesson) =>
          APIToModel.transformLesson(lesson as Lesson)
        );
      })
      .finally(() => setLoading(false));
  }, []);

  const saveLessonPage = React.useCallback(
    async (page: ILessonPage): Promise<{ pageId: string }> => {
      if (page.id) {
        const result = await updatePageMutation({
          variables: {
            updatePageId: page.id,
            data: { title: page.title ?? "" },
          },
        });

        if (!result.data?.updatePage.data?.id) {
          throw new Error(
            result.data?.updatePage.error?.message ??
              "Error occurred while updating page."
          );
        }

        return { pageId: result.data.updatePage.data.id };
      } else {
        const result = await createPageMutation({
          variables: {
            data: {
              lessonId: page.lessonId,
              pageOrder: page.order,
              title: page.title ?? "",
            },
          },
        });

        if (!result.data?.createPage.data?.id) {
          throw new Error(
            result.data?.createPage.error?.message ??
              "Error occurred while adding page."
          );
        }

        return { pageId: result.data.createPage.data.id };
      }
    },
    []
  );

  const saveLessonSection = React.useCallback(
    async (
      pageId: string,
      section: ILessonPageSection
    ): Promise<{ sectionId: string }> => {
      const sectionContent: SectionContentInput =
        ModelToAPIInput.transformContent(section.contentType, section.content);

      if (section.id) {
        return await updateSectionMutation({
          variables: {
            updateSectionId: section.id,
            data: { content: sectionContent },
          },
        }).then((result) => {
          if (!result.data?.updateSection.data) {
            throw new Error(
              result.data?.updateSection.error?.message ??
                "Unable to update section"
            );
          }
          return { sectionId: result.data.updateSection.data.id };
        });
      }
      return await createSectionMutation({
        variables: {
          data: {
            pageId,
            title: "",
            content: sectionContent,
            sectionOrder: section.order,
          },
        },
      }).then((result) => {
        if (!result.data?.createSection.data) {
          throw new Error(
            result.data?.createSection.error?.message ??
              "Unable to create section"
          );
        }
        return { sectionId: result.data.createSection.data.id };
      });
    },
    []
  );

  const deleteLessonSection = React.useCallback(
    async (sectionId: string): Promise<{ sectionId: string }> => {
      return await deleteSectionMutation({
        variables: {
          deleteSectionId: sectionId,
        },
      }).then((result) => {
        if (!result.data?.deleteSection.data) {
          throw new Error(
            result.data?.deleteSection.error?.message ??
              "Unable to delete section"
          );
        }
        return { sectionId: result.data.deleteSection.data.id };
      });
    },
    []
  );

  const deleteLesson = React.useCallback(
    async (lessonId: string): Promise<{ lessonId: string }> => {
      return await deleteLessonMutation({
        variables: {
          deleteLessonId: lessonId,
        },
      }).then((result) => {
        if (!result.data?.deleteLesson.data) {
          throw new Error(
            result.data?.deleteLesson.error?.message ??
              "Unable to delete lesson"
          );
        }
        return { lessonId: result.data.deleteLesson.data.id };
      });
    },
    []
  );

  return {
    loading,
    createLesson,
    updateLesson,
    fetchLesson,
    fetchFeaturedLessons,
    fetchLessonsByAuthorId,
    fetchAuthorIdByLesson,
    fetchPaginatedLessonsByAuthorId,
    fetchLessonsByTitle,
    saveLessonPage,
    saveLessonSection,
    deleteLessonSection,
    deleteLesson,
  };
}

export default useLessonApiClient;
