import React, { useState } from "react";
import styles from "./LessonContainer.module.css";
import useAILessonCreatorClient from "../../../../hooks/useAILessonCreatorClient";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faPenSquare,
  faSpinner,
  faWandMagicSparkles,
} from "@fortawesome/free-solid-svg-icons";
import { ILessonPageSection } from "../../../../types/lesson";
import { faLine } from "@fortawesome/free-brands-svg-icons";
import PromptInput from "../../../common_components/PromptInput/PromptInput";

export interface IContinueOrClarifySection {
  pageId: string;
  section: ILessonPageSection;
  lessonId: string;
  chatExists: boolean;
  continueStream: boolean;
  setContinueStream: (show: boolean) => void;
}

export const ContinueOrClarifySectionCard: React.FC<
  IContinueOrClarifySection
> = ({ pageId, section, lessonId, continueStream, setContinueStream }) => {
  const {
    generateNextSectionInLesson,
    clarifySectionWithChamAI,
    regenerateSectionContentFromPrompt,
  } = useAILessonCreatorClient();
  const [showMessageSection, setShowMessageSection] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showRegeneratePopover, setShowRegeneratePopover] =
    useState<boolean>(false);
  // If not continue with context, message box is for regenerate
  const [isContinueWithContext, setIsContinueWithContext] =
    useState<boolean>(false);
  const ref = React.useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: MouseEvent): void => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setShowRegeneratePopover(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleBackToOptions = React.useCallback(() => {
    setShowMessageSection(false);
    setShowRegeneratePopover(false);
  }, []);

  const submitRegenerateCurrentOrGenerateNextPrompt = React.useCallback(
    async (prompt: string) => {
      setIsLoading(true);
      setContinueStream(true);
      if (section.id && !continueStream) {
        try {
          if (isContinueWithContext) {
            await generateNextSectionInLesson(lessonId, pageId, prompt);
          } else {
            await regenerateSectionContentFromPrompt(
              lessonId,
              pageId,
              section.order,
              prompt
            );
          }
          setShowMessageSection(false);
          setShowRegeneratePopover(false);
        } catch (e) {
          console.error(e);
        }
      }
      setIsLoading(false);
    },
    [
      prompt,
      section.order,
      pageId,
      lessonId,
      isContinueWithContext,
      showMessageSection,
      continueStream,
      clarifySectionWithChamAI,
      generateNextSectionInLesson,
    ]
  );

  const handleGenerateNextSection = React.useCallback(() => {
    if (pageId && !continueStream) {
      setContinueStream(true);
      void generateNextSectionInLesson(lessonId, pageId);
    }
  }, [pageId, generateNextSectionInLesson, lessonId]);

  return (
    <div>
      {!showMessageSection ? (
        <div className={styles.continueGeneratingSection}>
          <div className={styles.innerContainer}>
            <div className={styles.inputTopic}>
              Click one of the following options to keep building!
            </div>
            <div className={styles.options}>
              <div
                className={`${styles.option} ${continueStream ? styles.disabled : ""}`}
                onClick={handleGenerateNextSection}
              >
                Keep going
              </div>

              <div
                className={`${styles.option} ${continueStream ? styles.disabled : ""}`}
                onClick={() => {
                  if (!continueStream) {
                    setIsContinueWithContext(true);
                    setShowMessageSection(true);
                  }
                }}
              >
                Go down a different path
              </div>

              <div
                className={`${styles.option} ${styles.popOption}`}
                onClick={() => {
                  setShowRegeneratePopover(true);
                }}
              >
                {showRegeneratePopover && (
                  <div ref={ref}>
                    {isLoading ? (
                      <div className={styles.menu}>
                        <FontAwesomeIcon
                          icon={faSpinner}
                          className={"fa-spin"}
                          color="DFE2E0"
                          style={{ padding: "5px" }}
                        />
                      </div>
                    ) : (
                      <div className={styles.contextMenu}>
                        <div className={styles.regenTitle}>
                          Regenerate section
                        </div>
                        <ul className={styles.list}>
                          <li
                            className={styles.item}
                            onClick={() => {
                              void submitRegenerateCurrentOrGenerateNextPrompt(
                                "Make it shorter"
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faPenSquare}
                              className={styles.iconB}
                            />{" "}
                            Shorter
                          </li>
                          <li
                            className={styles.item}
                            onClick={() => {
                              setIsContinueWithContext(false);
                              void submitRegenerateCurrentOrGenerateNextPrompt(
                                "Make it Longer"
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faLine}
                              className={styles.iconB}
                            />{" "}
                            Longer
                          </li>
                          <li
                            className={styles.item}
                            onClick={() => {
                              setIsContinueWithContext(false);
                              void submitRegenerateCurrentOrGenerateNextPrompt(
                                "Make it simpler"
                              );
                            }}
                          >
                            <FontAwesomeIcon
                              icon={faWandMagicSparkles}
                              className={styles.iconB}
                            />
                            Simpler
                          </li>
                          <li
                            className={styles.item}
                            onClick={() => {
                              setShowMessageSection(true);
                              setIsContinueWithContext(false);
                              setShowRegeneratePopover(false);
                            }}
                          >
                            Custom
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                Regenerate
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.continueGeneratingSection}>
          <div className={styles.innerFLUPContainer}>
            <div className={styles.backHeader}>
              <div className={styles.backIcon} onClick={handleBackToOptions}>
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              <div className={styles.inputTopic}>
                {isContinueWithContext
                  ? "What context would you like to provide?"
                  : "How would you like the content to be regenerated?"}
              </div>
            </div>

            <div className={styles.inputContainer}>
              <PromptInput
                handleSendMessage={(prompt) => {
                  void submitRegenerateCurrentOrGenerateNextPrompt(prompt);
                }}
                placeholder="Provide additional context"
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
